import { type LoginDetails, type RecaptchaToken } from 'bb/account/login/types';
import {
    type UseMutationPassableOptions,
    useMutation
} from 'bb/api/browser/useMutation';

export type LoginPayload = LoginDetails & RecaptchaToken;

export type RegisterAccountOptions = UseMutationPassableOptions<
    'post',
    '/api/accounts'
>;

export function useRegisterAccountSWR(options?: RegisterAccountOptions) {
    return useMutation('post', '/api/accounts', options);
}
