import { useEffect } from 'react';
import { Debug } from 'bb/app/debug';

const debug = Debug('useUserDetailsQueryParams');

export const useUserDetailsQueryParams = (
    callback: (queryParams: Record<string, unknown>) => void,
    queryParams: Record<string, unknown>
): void => {
    useEffect(() => {
        if (queryParams && callback && Object.keys(queryParams).length !== 0) {
            debug.info('setting user details');
            const { firstName = '', lastName = '', email = '' } = queryParams;

            callback({
                firstName,
                lastName,
                email
            });
        }
    }, [queryParams, callback]);
};
