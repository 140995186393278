// @ts-strict-ignore
import { useEffect, useState } from 'react';
import { useRetryPut } from 'bb/api/browser';
import { type Status, type ErrorResponse } from 'bb/api/browser/types';
import {
    ExternalPaymentResult,
    type AccountActivatedResponse
} from 'bb/registration/types';

type ActivateSubscriptionPayload = {
    uncomputedAdyenSignature: string;
    merchantReference: string;
    adyenSignature: string;
};

export function createUnsignedAdyenSignature(
    requestParams: Record<string, unknown>
): string {
    const keys = Object.keys(requestParams).filter(
        (key) => key !== 'merchantSig'
    );
    keys.sort();
    const signatureStringValues = keys.slice();
    keys.forEach((key) => {
        const val = String(requestParams[key]);
        signatureStringValues.push(
            val.replace(/\\/g, '\\\\').replace(/:/g, '\\:')
        );
    });
    return signatureStringValues.join(':');
}

const retryConfig = {
    maxTries: 50,
    delay: 1000,
    retryCondition: (response) => response?.Message === 'awaiting_confirmation'
};

export function usePendingActivateSubscription(
    queryParams: Record<string, unknown>
): [AccountActivatedResponse, ErrorResponse, Status] {
    const [payload, setPayload] = useState(null);
    const { result, error, status } = useRetryPut<
        AccountActivatedResponse,
        ActivateSubscriptionPayload
    >('/api/accounts/activate/external', retryConfig, payload);

    useEffect(() => {
        if (queryParams && Object.keys(queryParams).length !== 0) {
            const { authResult, merchantReference, merchantSig } = queryParams;
            if (authResult === ExternalPaymentResult.AUTHORISED) {
                const data = {
                    uncomputedAdyenSignature:
                        createUnsignedAdyenSignature(queryParams),
                    merchantReference,
                    adyenSignature: merchantSig
                };
                setPayload(data);
            }
        }
    }, [queryParams]);

    return [result, error, status];
}
