// @ts-strict-ignore
import React, { useContext } from 'react';
import { useLocalObservable } from 'bb/app/stores';
import { type FCChildrenOnly } from 'bb/common/types';
import { registrationStore } from '../registrationStore';
import { type RegistrationStore } from '../types';

export const StoreContext = React.createContext<RegistrationStore | null>(null);

export const RegistrationStoreProvider: FCChildrenOnly = ({ children }) => {
    const store = useLocalObservable(registrationStore);
    return (
        <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
    );
};

export const useRegistrationStore = (): RegistrationStore => {
    const store = useContext<RegistrationStore>(StoreContext);

    if (!store) {
        throw new Error(
            'useRegistrationStore must be used within a StoreProvider'
        );
    }
    return store;
};
