// @ts-strict-ignore
import { useEffect } from 'react';
import { type Product, type SubscriptionProducts } from 'bb/subscription/types';

export const useSubscriptionTypeQueryParams = (
    callback: (product: Product) => void,
    subscriptionProducts: SubscriptionProducts,
    queryParams: Record<string, unknown>
): void => {
    useEffect(() => {
        if (
            queryParams?.type &&
            callback &&
            Object.keys(queryParams).length !== 0
        ) {
            const { type = '' } = queryParams;
            const selectedProduct = subscriptionProducts.products.find(
                (product) => product.type === type
            );
            callback(selectedProduct);
        }
    }, [queryParams, callback, subscriptionProducts]);
};
