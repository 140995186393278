import { useEffect, useRef } from 'react';
import { usePost } from 'bb/api/browser';
import { REJECTED, RESOLVED } from 'bb/api/browser/symbols';
import { type Status, type ErrorResponse } from 'bb/api/browser/types';
import { type Campaign } from 'bb/campaigns/types';
import { useAnalyticsEvent, useRegistrationTracker } from 'bb/tracker';
import {
    type UserRegisteredResponse,
    type UserDetails,
    type RegistrationPayload
} from '../types';

const validRegistrationPayload = ({
    email,
    firstName,
    lastName,
    password,
    campaignCode,
    campaign,
    termsAndConditions
}: UserDetails & { campaign?: Campaign }): boolean =>
    Boolean(
        email &&
            firstName &&
            lastName &&
            password &&
            (campaignCode === '' || Boolean(campaign)) &&
            termsAndConditions
    );

const toRegistrationPayload = ({
    firstName,
    lastName,
    email,
    password,
    consents,
    campaignCode,
    recaptchaToken
}: UserDetails): RegistrationPayload => ({
    firstName,
    lastName,
    email,
    password,
    consents,
    campaignCode,
    recaptchaToken
});

export function useRegisterUser(
    userDetails: UserDetails,
    campaign: Campaign | undefined,
    withError: boolean
): [UserRegisteredResponse, ErrorResponse, Status] {
    const tracker = useRegistrationTracker();
    // const logger = useSimpleLogger();
    const { result, error, status, execute } = usePost<
        UserRegisteredResponse,
        RegistrationPayload
    >('/api/accounts');
    const prevEmail = useRef('');
    const prevCampaignCode = useRef<undefined | string>('');
    const hasStartedExecuting = useRef(false);
    const trackingComplete = useRef(false);

    const analyticsEvent = useAnalyticsEvent('Registration');

    const shouldExecute =
        !hasStartedExecuting ||
        prevEmail.current !== userDetails.email ||
        prevCampaignCode.current !== userDetails.campaignCode;

    useEffect(() => {
        if (
            userDetails &&
            !withError &&
            validRegistrationPayload({ ...userDetails, campaign }) &&
            shouldExecute
        ) {
            prevEmail.current = userDetails.email;
            prevCampaignCode.current = userDetails.campaignCode;
            hasStartedExecuting.current = true;
            execute(toRegistrationPayload(userDetails));
        }
    }, [userDetails, execute, shouldExecute, campaign, result, withError]);

    useEffect(() => {
        if (status === RESOLVED || status === REJECTED) {
            hasStartedExecuting.current = false;
        }
    }, [status]);

    useEffect(() => {
        if (result && !trackingComplete.current) {
            tracker.userRegistrationComplete(result?.userid, result?.email);
            analyticsEvent.trackUsersCookieConsent();
            trackingComplete.current = true;
        }
    }, [result, tracker, analyticsEvent, userDetails?.email]);
    return [result, error, status];
}
