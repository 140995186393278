import { useEffect } from 'react';
import { type AccountSettings } from 'bb/account/types';
import { usePost } from 'bb/api/browser';
import { PENDING } from 'bb/api/browser/symbols';
import { type ErrorResponse } from 'bb/api/browser/types';

type ActivateAccountWithGiftCardPayload = {
    code: string;
};

type ActivateAccountWithGiftCardResponse = {
    account: AccountSettings;
};

export function useActivateAccountWithGiftcard(
    giftcardCode: string,
    activate: boolean
): [ActivateAccountWithGiftCardResponse, ErrorResponse, boolean] {
    const { result, error, status, execute } = usePost<
        ActivateAccountWithGiftCardResponse,
        ActivateAccountWithGiftCardPayload
    >('/api/accounts/activate/giftcard');

    useEffect(() => {
        if (giftcardCode && activate) {
            execute({
                code: giftcardCode
            });
        }
    }, [giftcardCode, execute, activate]);

    return [result, error, status === PENDING];
}
