// @ts-strict-ignore
import { Debug } from 'bb/app/debug';
import {
    type GiftcardDetails,
    type RegistrationStore,
    type Consent
} from 'bb/registration/types';
import { CONSENT_PREFIX } from './utils';

const debug = Debug('registrationStore');

const initialState = {
    subscriptiontype: '',
    creditCardAlias: null,
    threeDsData: null,
    account: null,
    paymentMethodType: '',
    userId: null,

    campaignCode: null,
    isWaitingForAdditionalData: false,
    userDetails: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        campaignCode: '',
        recaptchaToken: null
    },
    externalFormData: {},
    paymentDetails: null,

    threeds20RequestData: {
        deviceChannel: 'browser',
        notificationURL: ''
    },
    allowEmail: '',
    activateWithGiftcard: false,
    giftcardCode: '',
    giftcard: null,
    completedRegistration: false,
    awaitAccountActivation: false
};

export function registrationStore(): RegistrationStore {
    return {
        ...initialState,
        setUserDetails(userDetailsFormData) {
            debug.info('setUserDetails');
            const consentNames = Object.keys(userDetailsFormData).filter(
                (name) => name.includes(CONSENT_PREFIX)
            );

            const consents = consentNames.map((name) => ({
                name: name.replace(CONSENT_PREFIX, ''),
                hasGivenConsent: userDetailsFormData[name]
            }));

            this.userDetails = {
                ...this.userDetails,
                ...userDetailsFormData,
                consents
            };
        },

        setInitialConsentsValues(consents: Array<Consent>) {
            debug.info('setInitialConsentsValues');
            const formattedConsent = consents.reduce(
                (flattenedConsents, consent) => ({
                    ...flattenedConsents,
                    [`${CONSENT_PREFIX}${consent.key}`]: consent.defaultValue
                }),
                {}
            );
            this.userDetails = {
                ...this.userDetails,
                ...formattedConsent
            };
        },

        setPaymentDetails(paymentDetails) {
            this.paymentDetails = {
                ...this.paymentDetails,
                ...paymentDetails
            };
        },

        setExternalFormData(externalFormData) {
            this.externalFormData = {
                ...this.externalFormData,
                ...externalFormData
            };
        },

        setAccount(data) {
            debug.info('setAccount');
            this.account = data;
        },

        setSubscriptionType(subscriptionType) {
            debug.info('setSubscriptionType');
            this.subscriptiontype = subscriptionType;
        },

        setCreditCardAlias(alias) {
            debug.info('setCreditCardAlias');
            this.creditCardAlias = alias || '';
        },

        setPaymentMethodType(paymentMethodType) {
            debug.info('setPaymentMethodType');
            this.paymentMethodType = paymentMethodType;
        },

        setCampaignCode(code) {
            this.campaignCode = code;
        },
        setCampaignCodeInUserDetails(code) {
            this.userDetails = {
                ...this.userDetails,
                campaignCode: code
            };
        },

        clearCampaign() {
            debug.info('clearCampaign');
            this.campaignFormValues = null;
            // NOTE: do not clear this.campaignCode
            // We rely on that the user has set this in order to show error messages

            this.userDetails = {
                ...this.userDetails,
                campaignCode: ''
            };
        },

        setCampaignFormValues(data) {
            debug.info('setCampaignFormValues');

            this.campaignFormValues = data;
        },

        setIsWaitingForAdditionalData(isWaitingForAdditionalData) {
            this.isWaitingForAdditionalData = isWaitingForAdditionalData;
        },

        clearCvcCode() {
            debug.info('clearCvcCode');
            this.paymentDetails = { ...this.paymentDetails, cvc: '' };
        },

        paymentError() {
            debug.info('clearCvcCode');
            this.paymentDetails = {
                ...this.paymentDetails,
                cvc: '',
                clearData: true
            };
        },

        clearTermsAndConditions() {
            debug.info('clearTermsAndConditions');
            this.userDetails = {
                ...this.userDetails,
                termsAndConditions: false
            };
        },

        clearUserDetails() {
            debug.info('clearUserDetails');
            this.userDetails = initialState.userDetails;
            this.subscriptiontype = initialState.subscriptiontype;
            this.creditCardAlias = initialState.creditCardAlias;
        },
        clearOnlyUserDetails() {
            debug.info('clearOnlyUserDetails');
            this.userDetails = initialState.userDetails;
        },

        clearAccount() {
            debug.info('clearAccount');
            this.account = initialState.account;
        },

        setAllowEmail(value) {
            this.allowEmail = value;
        },

        setActivateWithGiftcard(giftcardCode) {
            debug.info('setActivateWithGiftcard');
            this.activateWithGiftcard = true;
            this.giftcardCode = giftcardCode;
        },

        clearActivateWithGiftcard() {
            this.activateWithGiftcard = false;
        },

        setGiftcard(giftcardDetails: GiftcardDetails) {
            this.giftcard = giftcardDetails;
        },

        resetAdditionalPaymentData() {
            debug.info('resetAdditionalPaymentData');
            this.paymentDetails = {
                ...this.paymentDetails,
                paymentData: {
                    ...this.paymentDetails.paymentData,
                    threeDsResult: null,
                    merchantReference: null,
                    paypalResult: null,
                    paymentMethodType: null
                }
            };
            this.pendingMerchantReference = null;
        },

        setPendingMerchantReference(merchantReference) {
            debug.info('setPendingMerchantReference');
            this.pendingMerchantReference = merchantReference;
        },

        resetCreditcardData() {
            debug.info('setPendingMerchantReference');
            this.paymentDetails = {};
            this.creditCardAlias = {};
        },
        setCompletedRegistration() {
            debug.info('setCompletedRegistration');
            this.completedRegistration = true;
        },
        setAwaitAccountActivation(awaitAccountActivation) {
            debug.info('setAwaitAccountActivation');
            this.awaitAccountActivation = awaitAccountActivation;
        }
    };
}
