import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { fetcher } from 'bb/api/browser';
import type { ErrorResponse } from 'bb/api/browser/types';
import { getHalLinkHref } from 'bb/common/hal';
import {
    useStableCallback,
    type UsePollingOptions,
    usePolling
} from 'bb/common/hooks';
import type { HalLink } from 'bb/common/types';
import { is, shortSwitch } from 'bb/utils';
import type { AccountActivationState } from '..';

export type ActivationState = AccountActivationState['state'] | 'Timeout';

export type ActivationDoneState = Exclude<ActivationState, 'None' | 'Pending'>;

export type Result = {
    state: AccountActivationState['state'];
    message?: string;
};

export type UseActivationStateConfig = Omit<
    UsePollingOptions<Result>,
    'onSuccess'
> & {
    onDone?: (
        state: ActivationDoneState | ErrorResponse,
        duration: number,
        message?: string
    ) => void;
};

const getMessageKey = (state: ActivationState) =>
    shortSwitch(
        state,
        ['None', 'activationStatus-none'],
        ['Pending', 'activationStatus-pending'],
        ['TrialBlocked', 'activationStatus-trialblocked'],
        ['GeoBlocked', 'activationStatus-ipblocked'],
        ['VerificationBlocked', 'activationStatus-verificationblocked'],
        ['Completed', 'activationStatus-completed'],
        ['Timeout', 'activationStatus-timeout']
    );

export const useActivationState = (
    link?: string | HalLink,
    config: UseActivationStateConfig = {}
) => {
    const { onDone: _onDone, ...restConfig } = config;

    const { t: _t } = useTranslation('registration');
    const t = (key?: string) => key && _t(key);
    const finalized = useRef(false);

    const url = typeof link === 'string' ? link : getHalLinkHref(link);
    const onDone = useStableCallback(_onDone);

    const {
        data: result,
        timedOut,
        isSlow,
        isDone,
        duration,
        error,
        enabled
    } = usePolling<Result>(url, {
        ...restConfig,
        fetcher: (path: string) =>
            fetcher<AccountActivationState>(path).then(({ state }) => ({
                state,
                message: t(getMessageKey(state))
            })),
        onSuccess: (res) => !is(res?.state, 'anyOf', 'None', 'Pending'),
        onError: (...rest) => {
            config.onError?.(...rest);
        }
    });

    const state: ActivationState = timedOut
        ? 'Timeout'
        : (result?.state ?? 'None');
    const message = timedOut ? t(getMessageKey(state)) : result?.message;

    const finalize = useStableCallback(() => {
        onDone(error ?? (state as ActivationDoneState), duration, message);
        finalized.current = true;
    });

    useEffect(() => {
        if (isDone && !finalized.current) finalize();
    }, [finalize, isDone]);

    return {
        duration,
        state,
        message,
        isCompleted: isDone && state === 'Completed',
        isDone,
        isFailure: isDone && state !== 'Completed',
        isSlow: !isDone && isSlow,
        isLoading: enabled && !isDone,
        error: isDone ? error : undefined
    };
};
