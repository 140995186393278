// @ts-strict-ignore
import { type Product } from 'bb/subscription/types';
import { type GiftcardProduct, type GiftcardProducts } from './types';

export const validGiftcardRegex = /^(\w{5}-)(\w{5}-)(\w{5}-)(\w{5})$/;

export const isGiftCardRegex = /^(?!\w{5}-\w{5}-\w{5}-\w{5}).*/;

// Insert a dash every 5 letters
// Remove tailing dash
// Remove double dashes
export const handleGiftardValueChange = (value: string): string =>
    value
        ?.replace(/(\w{5})/g, '$1-')
        .replace(/(-$)/, '')
        .replace(/--/g, '-') // if user pasted a "correct" code, replace double dashes introduced by regexp
        .toUpperCase();

export const formatProducts = (
    products: Array<GiftcardProduct> = []
): GiftcardProducts =>
    [...products]
        ?.sort((p1, p2) => p1.price - p2.price)
        .reduce((list, product) => {
            if (!list[product.subscriptiontype]) {
                // eslint-disable-next-line no-param-reassign
                list[product.subscriptiontype] = [];
            }
            list[product.subscriptiontype].push(product);
            return list;
        }, {});

export const getListOfProducts = (
    giftcardProducts: Record<string, GiftcardProduct[]>,
    subscriptionProducts: Product[]
): Product[] =>
    giftcardProducts &&
    Object.keys(giftcardProducts)
        ?.map((product) => {
            const subscriptionProduct = subscriptionProducts?.find(
                (s) => s?.type === product
            );
            if (!subscriptionProduct) {
                return null;
            }
            const giftcardProduct = giftcardProducts[product]?.[0];
            return {
                ...subscriptionProduct,
                price: giftcardProduct?.price
            };
        })
        .filter((product) => product);
