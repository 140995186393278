import React, { type FC } from 'react';
import { Text } from 'bb/i18n';
import {
    type Campaign,
    type CampaignResponse,
    EligibilityRule
} from '../types';

export const excludeExistingCustomers = (
    campaign: Campaign | CampaignResponse
): boolean => {
    if (!campaign) {
        return true;
    }
    return Boolean(
        campaign?.eligibilityRules?.includes(EligibilityRule.excludeExisting)
    );
};

export const excludeNewCustomers = (
    campaign: Campaign | CampaignResponse
): boolean =>
    Boolean(campaign?.eligibilityRules?.includes(EligibilityRule.excludeNew));

export const EligibilityRulesMessage: FC<{
    campaign: Campaign | CampaignResponse;
    textKeySuffix?: string;
}> = ({ campaign, textKeySuffix = '' }) => (
    <>
        {excludeExistingCustomers(campaign) && (
            <Text t={`campaign:newCustomersOnly${textKeySuffix}`} center />
        )}
        {excludeNewCustomers(campaign) && (
            <Text t={`campaign:existingCustomersOnly${textKeySuffix}`} center />
        )}
    </>
);
