import { type BBApiTypes } from 'bb/api/types';
import { type VerifyRecaptchaPayload } from 'bb/auth/types';
import {
    type CampaignFormValues,
    type CampaignPayload
} from 'bb/campaigns/types';
import { type HalObject } from 'bb/common/types';
import { type LinkPartnerPage as PartnerPage } from 'bb/page/types';
import {
    type AdyenData,
    type AdyenFormData,
    type ThreeDS20RequestData,
    type ThreeDsData
} from 'bb/payment/adyen';
import { type CONSENT_PREFIX } from './utils';

export type ConsentPayload = {
    name: string;
    hasGivenConsent: boolean;
};

export type ConsentKey = `${typeof CONSENT_PREFIX}${string}`;

export type UserDetails = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    campaignCode?: string;
    consents?: Array<ConsentPayload>;
    termsAndConditions?: boolean;
    recaptchaToken?: string | null;
    signupFlow?: SignupFlow;
} & {
    [s: ConsentKey]: boolean;
} & VerifyRecaptchaPayload;

export type SignupFlow = 'NormalWeb' | 'NormalApp' | 'EditionSingleSalesWeb';

export type RegistrationPayload = Omit<UserDetails, 'termsAndConditions'>;
export type PartnerRegistrationPayload = RegistrationPayload & {
    customerId: string;
};

export type GiftcardDetails = {
    code: string;
    activationdate: Date;
    cancelationdate: Date;
    email: string;
    createdat: Date;
    productname: string;
    validmonths: number;
    validdays: number;
    subscriptiontype: string;
    subscriptiontypedisplayname: string;
    paymentDetailsRequired: true;
    behaviours: [
        {
            key: string;
            properties: Record<string, unknown>;
        }
    ];
};

export type UserRegisteredResponse = HalObject<
    {
        user: {
            token: string;
            refreshtoken: string;
        };
    },
    'update' | 'changeEmail' | 'changePassword'
> & {
    userid: number;
    email: string;
    firstname?: string;
    lastname?: string;
    displayname?: string;
    subscriptionvalidto?: string;
    isKid?: boolean;
};

export type PartnerUserRegisteredResponse = UserRegisteredResponse & {
    paymentprovideridentifier: string;
    productgroupidentifier: string;
};

export type AccountActivatedResponse = {
    account: {
        email: string;
        hasOngoingPeriod: boolean;
        isActivated: boolean;
        _links?: {
            self: { href: string };
            profiles: { href: string };
            periods: { href: string };
            createprofile: { href: string };
        };
    };
};

export type CreditCardAlias = {
    alias: string;
    eligible: boolean;
};

export type RegistrationStore = {
    subscriptiontype: string;
    creditCardAlias: CreditCardAlias | null;
    paymentMethodType: string;
    userDetails: UserDetails;
    threeds20RequestData?: ThreeDS20RequestData;
    threeDsData?: ThreeDsData;
    account?: UserRegisteredResponse;
    campaignCode?: string;
    campaignFormValues?: CampaignFormValues;
    isWaitingForAdditionalData?: boolean;
    allowEmail: string;
    paymentDetails: AdyenFormData | null;
    externalFormData: Record<string, unknown>;
    activateWithGiftcard?: boolean;
    giftcardCode?: string;
    giftcard?: GiftcardDetails;
    pendingMerchantReference?: string;
    completedRegistration: boolean;
    awaitAccountActivation: boolean; // TODO Remove after sessions is 100% released on all markets
    setUserDetails(userDetailsFormData: UserDetails): void;
    setPaymentDetails(paymentDetails: AdyenFormData): void;
    setAccount(data: UserRegisteredResponse): void;
    setSubscriptionType(subscriptionType: string): void;
    setCreditCardAlias(data: CreditCardAlias): void;
    setPaymentMethodType(paymentMethodType: string): void;
    setCampaignCode(code: string): void; // Use before campaign is validated
    setCampaignFormValues(data: CampaignFormValues): void;
    setIsWaitingForAdditionalData(isWaitingForAdditionalData: boolean): void;
    clearCampaign(): void;
    setInitialConsentsValues(consents: Array<Consent>): void;
    clearCvcCode(): void;
    clearTermsAndConditions(): void;
    clearUserDetails(): void;
    clearOnlyUserDetails(): void;
    clearAccount(): void;
    setAllowEmail(value: string): void;
    paymentError(): void;
    setExternalFormData(data: Record<string, unknown>): void;
    setActivateWithGiftcard(giftcardCode: string): void;
    clearActivateWithGiftcard(): void;
    setGiftcard(giftcardDetails: GiftcardDetails): void;
    resetAdditionalPaymentData(): void;
    setPendingMerchantReference(merchantReference: string): void;
    resetCreditcardData(): void;
    setCompletedRegistration(): void;
    setCampaignCodeInUserDetails(code: string): void;
    setAwaitAccountActivation: (awaitActivation: boolean) => void; // TODO Remove after sessions is 100% released on all markets
};

export enum ExternalPaymentResult {
    AUTHORISED = 'AUTHORISED',
    REFUSED = 'REFUSED',
    CANCELLED = 'CANCELLED',
    PENDING = 'PENDING',
    ERROR = 'ERROR'
}

export type Consent = {
    key?: string | null;
    defaultValue: boolean;
    hidden: boolean;
};

export type ConsentResponse = {
    market: string;
    consents: Array<Consent>;
};

/**
 * Like the above but with generated types from Swagger. Prefer to use this.
 */
export type ConsentsResponse = BBApiTypes['ApiMarketConsentsResponse'];

export type PartnerConsentResponse = ConsentResponse & {
    paymentProvider: string;
};

export type StoredActivationData = {
    subscriptionType: string;
    alias?: CreditCardAlias;
    campaignFormValues?: CampaignFormValues;
};

export type ActivationData = {
    paymentData: AdyenData;
    subscriptionType: string;
    alias: string;
    isLoading: boolean;
    campaign?: CampaignPayload;
};

export type AccountActivationState = {
    state:
        | 'None'
        | 'Pending'
        | 'TrialBlocked'
        | 'GeoBlocked'
        | 'VerificationBlocked'
        | 'Completed'
        | 'CampaignBlocked';
};

export type VerificationMethodProps<T = string> = {
    next(type?: string, data?: T): void;
    prev(): void;
    links: PartnerPage['_links'];
    userData: Map<string, T>;
    partnerName: string;
};

export type VerifyCustomerIdPayload = {
    customerId: string;
};
export type VerifyPinPayload = {
    customerId: string;
    code: string;
};

export type LinkAccountActivationPayload = {
    subscriptionType: string;
};
