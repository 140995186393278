/* eslint-disable react/jsx-props-no-spreading */
// @ts-strict-ignore
import React, { type FC } from 'react';
import { useRouter } from 'bb/app/router';
import { observer } from 'bb/app/stores';
import { EligibilityRulesMessage } from 'bb/campaigns/components/EligibilityRulesMessage';
import { isLifetime } from 'bb/campaigns/utils';
import { GiftcardSubscriptionText, PriceRuleText } from 'bb/common';
import { Header } from 'bb/i18n';
import { useSubscriptionStore } from 'bb/subscription';
import { match } from 'bb/utils';
import { useRegistrationStore } from '../hooks';

export const zeroTrialDaysKeySuffix = '-ZeroTrialDays';

type SignupInformationProps = {
    defaultTrialDays: number;
    campaignIsLoaded: boolean;
    formId: string;
};

type SignupInformationSubComponentProps = {
    hourLimit: number;
    lifeTimeInDays: number;
    hasLoadedCampaign: boolean;
    showCampaignText: boolean;
} & SignupInformationProps;

const isLifeTimeWithHourLimit = ({ days, hourLimit }) =>
    hourLimit > 0 && isLifetime({ days });
const isLifetimeWithoutHourLimit = ({ days, hourLimit }) =>
    !hourLimit && isLifetime({ days });
const isNotLifetimeWithHourLimit = ({ days, hourLimit }) =>
    hourLimit > 0 && !isLifetime({ days });
const isNotLifetimeWithoutHourLimit = ({ days, hourLimit }) =>
    !hourLimit && !isLifetime({ days });

const textKey = (days: number, hourLimit: number, zeroTrialDays = false) => {
    const maybeZeroTrialDaysSuffix = zeroTrialDays
        ? zeroTrialDaysKeySuffix
        : '';

    return match({ days, hourLimit })
        .on(
            isLifeTimeWithHourLimit,
            () =>
                `registration:userDetailsSubHeaderHourLimit-lifetimeDiscount${maybeZeroTrialDaysSuffix}`
        )
        .on(
            isLifetimeWithoutHourLimit,
            () =>
                `registration:userDetailsSubHeader-lifetimeDiscount${maybeZeroTrialDaysSuffix}`
        )
        .on(
            isNotLifetimeWithHourLimit,
            () =>
                `registration:userDetailsSubHeaderHourLimit${maybeZeroTrialDaysSuffix}`
        )
        .on(
            isNotLifetimeWithoutHourLimit,
            () => `registration:userDetailsSubHeader${maybeZeroTrialDaysSuffix}`
        )
        .otherwise(() => '');
};

const SignupInformationWithTrialDays: FC<SignupInformationSubComponentProps> =
    observer(
        ({ defaultTrialDays, hourLimit, lifeTimeInDays, showCampaignText }) => {
            const registrationStore = useRegistrationStore();
            const subscriptionStore = useSubscriptionStore();

            const trialDays =
                subscriptionStore.campaign?.trialDays ||
                subscriptionStore.subscriptionProducts?.trialDays ||
                defaultTrialDays;

            const subheaderTextKey =
                hourLimit > 0
                    ? 'registration:userDetailsSubHeaderHourLimit'
                    : 'registration:userDetailsSubHeader';

            const dataOptimizelyId = showCampaignText
                ? undefined
                : 'signup-pricerule-text';
            return (
                <>
                    <Header
                        size="medium"
                        center
                        t="registration:userDetailsHeader"
                        data-optimizely="signup-header"
                        margin="none"
                    />
                    {registrationStore.giftcard ? (
                        <GiftcardSubscriptionText
                            center
                            giftcard={registrationStore.giftcard}
                            textKey={subheaderTextKey}
                        />
                    ) : (
                        <PriceRuleText
                            center
                            t={textKey(lifeTimeInDays, hourLimit)}
                            priceRule={subscriptionStore.campaign?.priceRule}
                            nrOfDays={trialDays}
                            dataOptimizelyId={dataOptimizelyId}
                            hourLimit={hourLimit}
                        >
                            <strong />
                        </PriceRuleText>
                    )}
                    <EligibilityRulesMessage
                        campaign={subscriptionStore?.campaign}
                    />
                </>
            );
        }
    );

const SignupInformationWithZeroTrialDays = observer(
    ({ hourLimit, lifeTimeInDays }: SignupInformationSubComponentProps) => {
        const registrationStore = useRegistrationStore();
        const subscriptionStore = useSubscriptionStore();

        const trialDays = subscriptionStore.campaign?.trialDays || 0;
        const subheaderTextKey =
            (hourLimit > 0
                ? 'registration:userDetailsSubHeaderHourLimit'
                : 'registration:userDetailsSubHeader') + zeroTrialDaysKeySuffix;

        return (
            <>
                <Header
                    size="medium"
                    center
                    t="registration:userDetailsHeader"
                    data-optimizely="signup-header"
                    margin="none"
                />
                {registrationStore.giftcard ? (
                    <GiftcardSubscriptionText
                        center
                        giftcard={registrationStore.giftcard}
                        textKey={subheaderTextKey}
                    />
                ) : (
                    <PriceRuleText
                        center
                        t={textKey(lifeTimeInDays, hourLimit, true)}
                        priceRule={subscriptionStore.campaign?.priceRule}
                        nrOfDays={trialDays}
                        hourLimit={hourLimit}
                    >
                        <strong />
                    </PriceRuleText>
                )}
                <EligibilityRulesMessage
                    campaign={subscriptionStore?.campaign}
                    textKeySuffix={zeroTrialDaysKeySuffix}
                />
            </>
        );
    }
);

export const SignupInformation = observer((props: SignupInformationProps) => {
    const { campaignIsLoaded } = props;

    const subscriptionStore = useSubscriptionStore();
    const { router } = useRouter();

    const hasZeroTrialDays = subscriptionStore.campaign?.hasPrepaidDays;
    const hasLoadedCampaign = router.query.campaign ? campaignIsLoaded : true;
    const showCampaignText = router.query.campaign ? campaignIsLoaded : false;
    const hourLimit =
        subscriptionStore.campaign?.hourLimit ||
        (!subscriptionStore.campaign?.code &&
            subscriptionStore.subscriptionProducts?.trialHours);
    const lifeTimeInDays =
        subscriptionStore.campaign?.priceRules?.length &&
        subscriptionStore.campaign?.priceRules[0]?.lifeTimeInDays;

    const commonProps = {
        hourLimit,
        lifeTimeInDays,
        hasLoadedCampaign,
        showCampaignText
    };

    // TODO: Add better copy for when we are loading campaign?
    if (!hasLoadedCampaign) {
        return null;
    }

    return hasZeroTrialDays ? (
        <SignupInformationWithZeroTrialDays {...props} {...commonProps} />
    ) : (
        <SignupInformationWithTrialDays {...props} {...commonProps} />
    );
});
